
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#FFFFFF'
    }
  }
}
