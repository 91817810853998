
const colors = {
  primary: 'border-transparent text-white bg-primary-500 hover:bg-primary-600 focus:ring-primary-500 hover:text-gray-200',
  secondary: 'border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500 hover:text-primary-700',
  black: 'border-transparent text-white bg-gray-900 hover:bg-gray-700 focus:ring-gray-500',
  white: 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-50'
}

const sizes = {
  base: 'text-base',
  xs: 'text-xs',
  sm: 'text-sm',
  xl: 'text-xl'
}

export default {
  props: {
    color: {
      required: false,
      type: String,
      default: 'primary'
    },
    size: {
      required: false,
      type: String,
      default: 'base'
    },
    block: {
      required: false,
      type: Boolean,
      default: false
    },
    rounded: {
      required: false,
      type: Boolean,
      default: true
    },
    to: {
      type: String,
      default: null
    }
  },
  computed: {
    type () {
      if (this.to) {
        return 'nuxt-link'
      } else {
        return 'button'
      }
    },
    colorsClass () {
      return colors[this.color]
    },
    sizeClass () {
      return sizes[this.size]
    },
    roundedClass () {
      return this.rounded ? 'rounded' : ''
    },
    displayClass () {
      return this.block ? 'block w-full' : 'inline-block'
    },
    classList () {
      const classList = `${this.displayClass} ${this.colorsClass} ${this.sizeClass} ${this.roundedClass}`

      return classList
    }
  }
}
